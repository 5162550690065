import axios from 'axios';
import { LOGOUT } from '../container/Login/constants';
import store from '../store';
const env = process.env.NODE_ENV;

const version = 'v2'

// const apiV2 = axios.create({
//     baseURL:
//         env === 'production'
//             ? `https://actipod.zedblox.com/api/${version}`
//             : `/api/${version}`,
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });

// //development
const apiV2 = axios.create({
    baseURL:
        env === 'production'
            ? `https://development.zedblox.com/api/${version}`
            : `/api/${version}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/

apiV2.interceptors.response.use(
    res => res,
    err => {
        if (err.response.status === 401) {
            store.dispatch({ type: LOGOUT });
        }
        return Promise.reject(err);
    }
);

export default apiV2;
