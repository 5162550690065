import {
    GET_OFFSET_LOADING,GET_DEVICES, ERROR_DEVICES, CLEAR_DEVICES, GET_DEVICE_RECORDS_TRIPS,CHNG_PAGE
} from "./constants";

const initialState = {
    id: null,
    name: null,
    devModel:null,
    loading: true,
    error: '',
    devices: [],
    splitedDevice:[],
    devRecordsTrips: [],
    active: 0,
    count: 0,
    limit: 0,
    offset: 0,
    tripDevices:0
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_OFFSET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case GET_DEVICES:
            return {
                ...state,
                devices: payload.devices,
                active: payload.active,
                count: payload.devices.length,
                splitedDevice:payload.splitedDevice,
                tripDevices:payload.tripDev_count,
                loading: false,
                name: null
            }
        case CHNG_PAGE:
            return {
                ...state,
                splitedDevice:payload.splitedDevice
            }
        case GET_DEVICE_RECORDS_TRIPS:
            return {
                ...state,
                devRecordsTrips: payload,
                loading: false,
                name: null
        }
        case ERROR_DEVICES:
            return {
                ...state,
                error: payload,
                loading: false,
                count: 0,
                name: null
            }
        case CLEAR_DEVICES:
            return initialState
        default:
            return state;
    }
}