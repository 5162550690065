import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const DevicesMain = styled.div`
    position: relative;
    display:flex;
    justify-content:center;
    width:100%;
    padding:0 2px;
`

export const FlexContainer = styled.div`
    display: flex;
    width:100%;
    scroll-behavior: smooth;
    overflow-x: ${({ stopOverflow }) => stopOverflow ? 'hidden' : 'auto'};
    &::-webkit-scrollbar {
        display: flex;
    }
`

export const FlexItem = styled.div`
flex: 0 0 auto;
padding-right:.7rem;
`

const Direction = styled.img`
    cursor: pointer;
    width:3.5rem;
    position:absolute;
    top:50%;
    z-index: 100;
`
export const GoLeft = styled(Direction)`
    left:0;
    transform: translate(-20%,-50%);
`
export const GoRight = styled(Direction)`
    left:100%;
    transform:translate(-80%,-50%);
`

export const Grid = styled.div`
display: grid;
grid-template-columns: ${props => props.col};
grid-template-rows: ${props => props.row};
grid-gap:${props => props.gap};
grid-column-gap:${props => props.cgap};
grid-row-gap:${props => props.rgap};
grid-column-gap: ${props => props.colGap};
padding:${props => props.padding};
`

export const Card = styled.div`
cursor:pointer;
padding: 10px;
width:100%;

`
export const CardHeader = styled(Grid)`
// background: ${props => props.active ? "#F0F6FF 0% 0% no-repeat padding-box" : "transparent linear-gradient(180deg, #F4F4F4 0%, #EAEAEA 100%) 0% 0% no-repeat padding-box"};
// border-radius: 8px 8px 0px 0px;
// opacity: 1;
padding-bottom: 10px;
border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`
export const CardFooter = styled(Grid)`
// background: ${props => props.active ? "#F0F6FF 0% 0% no-repeat padding-box" : "transparent linear-gradient(180deg, #F4F4F4 0%, #EAEAEA 100%) 0% 0% no-repeat padding-box;"};
// border-radius: 0px 0px 8px 8px;
// opacity: 1;
// padding: .4rem .5rem;
border-top: 1px solid rgba(0, 0, 0, 0.125);
`

export const Invisiborder = styled.div`
    position: absolute;
  pointerEvents: none;
  width: 100%;
  height: 100%;
  top: 0;
  border: 1px solid #dde4ed;
  pointer-events: none;
  border-radius: 10px;

`

export const StyledLink = styled(Link)`
    color: #FFFFFF;
    text-decoration: none;
    &:hover {
        color: #FFFFFF;
        text-decoration: none;
      }
    `
export const DeviceImage = styled.div`
display: flex;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 6px #0000002E;
border-radius: 10px;
opacity: 1;
width: 4rem;
height: 4rem;
`
export const Image = styled.img`
width: 3rem;
height: 2rem;
margin: auto;
`
export const Header1 = styled.div`
text-align: left;
font: normal normal normal 20px Helvetica;
letter-spacing: 0px;
color: #3B3B3B;
opacity: 1;
`
export const Header2 = styled.div`
text-align: ${props => props.align || 'left'};
font: ${props => props.bold ? 'bold' : 'normal'} normal normal ${props => props.font || "16"}px Helvetica;
letter-spacing: 0px;
color: ${props => props.color || '#3B3B3B'};
opacity: 1;
`
export const SubHeader = styled.div`
text-align: ${props => props.align || 'left'};
font: normal normal normal ${props => props.font || "12"}px Helvetica;
letter-spacing: 0px;
opacity: 1;
color: ${props => props.titleColor || "#AFAFAF"};
`
export const Span = styled.span`
color: ${props => props.color || "#797979"};
//font: ${props => props.font ? null : 'normal normal normal 16px/26px Helvetica'};
letter-spacing: 0px;
`
export const Title = styled.h5`
text-align: ${props => props.align || "center"};
font: normal normal bold ${props => props.font || "14"}px Helvetica;
letter-spacing: 0px;
color: ${props => props.color || "#3B3B3B"};
opacity: 1;
margin-bottom: 0;
`
export const SubTitle = styled.div`
text-align: ${props => props.align || "center"};
font: normal normal bold ${props => props.font || "11px"} Helvetica;
letter-spacing: 0px;
color: #AFAFAF;
text-transform: uppercase;
padding: 5px 0;
`
export const Num = styled.div`
text-align: center;
font: normal normal bold ${({font})=>font ? font : '20px'} Helvetica;
letter-spacing: 0px;
color: ${props => props.color || "#3B3B3B"};
// padding: 5px 0;

`
export const MainButton = styled.button`
background: ${props => props.bgColor || "#AFAFAF"};
border: 1px solid ${props => props.bgColor || "#AFAFAF"};
border-radius: ${props => props.borderRaduis || "5px"};
opacity: 1;
padding:0.25rem;
&:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem ${props => props.focus || "rgba(0, 123, 255, 0.25)"};
}
`
export const ButtonText = styled.span`
text-align: center;
font: normal normal bold 12px Helvetica;
letter-spacing: 0px;
color: ${props => props.color || "#FFFFFF"};
opacity: 1;
`


export const BtnLink = styled(Link)`
display: flex;
align-items: center;
justify-content: center;
text-align: center;
vertical-align: middle;
user-select: none;
padding: .545rem .75rem;
font: normal normal bold .75rem Helvetica;
line-height: 1.5;
border-radius: 5px;
letter-spacing: 0px;
white-space:nowrap;
opacity: 1;
transition:all 0.2s ease-in-out;
background: #66BA63 0% 0% no-repeat padding-box;
color:#fff;
border: 1px solid #66BA63;
cursor:pointer;
transition:all 0.2s ease-in-out;
text-decoration:none;
height: 2.5rem; /* Adjust height to match Start Trip button */
width: 100%; /* Fill the parent container */
&:hover{
transition:all 0.2s ease-in-out;
background:0 0 0 0.2rem rgba(72,180,97,.5) 0% 0% no-repeat padding-box;
color:#fff;
text-decoration:none;
}
`