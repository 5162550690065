import {
    GET_DEVICEDATA_LOADING,GET_OFFSET_LOADING,GET_SELECTED_DEVICE_TRIP_DATA, ERROR_SELECTED_DEVICE_TRIP_DATA, CLEAR_SELECTED_DEVICE_TRIP_DATA, CLEAR_SELECTED_DEVICE_DATA,GET_SELECTED_DEVICE_DATA,ERROR_SELECTED_DEVICE_DATA
} from "./constants";

const initialState = {
    id: null,
    name: null,
    loading: true,
    error: '',
    trips: [],
    active: 0,
    count: 0,
    limit: 0,
    offset: 0,
    devData: null,
    devModel: null,
    Tripsloading:true,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
         case GET_OFFSET_LOADING:
            return {
                ...state,
                error: '',
                Tripsloading: true,
            }
        case GET_DEVICEDATA_LOADING:
            return {
                ...state,
                error: '',
                Tripsloading: true,
            }
        
        case GET_SELECTED_DEVICE_TRIP_DATA:
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                devModel:payload.devModel,
                trips: payload.trips,
                limit: payload.limit,
                offset: payload.offset,
                count: payload.count,
                loading: false,
                error: '',
                Tripsloading:false
            }
            case GET_SELECTED_DEVICE_DATA:
                return {
                    ...state,
                    devData: payload.name,
                    devModel:payload.devModel,
                    loading: false,
                    error: '',
                    trips:[],
                    Tripsloading:false
                }
        case ERROR_SELECTED_DEVICE_DATA:
        case ERROR_SELECTED_DEVICE_TRIP_DATA:
            return {
                ...state,
                error: payload,
                loading: false,
                count: 0,
                name: null,
                devData: null,
                trips:[],
                Tripsloading:false
            }
        case CLEAR_SELECTED_DEVICE_DATA:
        case CLEAR_SELECTED_DEVICE_TRIP_DATA:
            return initialState
        default:
            return state;
    }
}