import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect,Router } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from './StyledComponents';
import { login } from './actions'
import InputField from '../../components/Forms/InputField';
import { Validators } from '../../utils/Validator';
import Alerts from '../../components/Alerts';
import { Container as LoginContainer, LoginCard } from './StyledComponents';
import { Copyright, Hero } from './helper';



const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(3, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(6),
    },
    forgot: {
        marginTop: '-.5rem'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const LoginForm = ({ login, isAuthenticated }) => {
    const classes = useStyles();

    // State variable for Remember Me checkbox
    const [rememberMe, setRememberMe] = useState(false);

    // Handle change in Remember Me checkbox
    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    const [user, setUser] = useState({
        Email: '',
        Password: ''
    });
    const { Email, Password } = user;
    const isEnabled = Email.length > 0 && Password.length > 0;
    const handleChange = (value, name) => setUser({ ...user, [name]: value });
    const onSubmit = e => {
        e.preventDefault();
        login(Email, Password ,rememberMe);
    };
    //Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to="/app/dashboard" />
    }
    return (
        <LoginContainer>
            <LoginCard col="repeat(2, 1fr)" row="1fr">
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography data-testid="counter" color='#585858' styleF='oblique' units='300' size='20px/24px'>
                            Welcome to ZedBlox Portal
                        </Typography>
                        <Alerts postion={'relative'} />
                        <form className={classes.form} onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputField
                                        id='Email'
                                        name='Email'
                                        type='Email'
                                        label='Email Id'
                                        value={Email}
                                        validators={[
                                            {
                                                check: Validators.required,
                                                message: 'Email Id cannot be empty'
                                            },
                                            {
                                                check: Validators.email,
                                                message: 'Enter a valid Email Id'
                                            }
                                        ]}
                                        onChange={handleChange}
                                        required={true}
                                        placeholder={'Enter your email id'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField
                                        id='Password'
                                        name='Password'
                                        type='Password'
                                        label='Password'
                                        value={Password}
                                        validators={[
                                            {
                                                check: Validators.required,
                                                message: 'Password cannot be empty'
                                            }
                                        ]}
                                        onChange={handleChange}
                                        required={true}
                                        placeholder={'Enter your password'}
                                    />
                                </Grid>

                                <Grid item xs={6} sm={6} container justify="flex-start">
                                    <FormControlLabel
                                        control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} value="allowExtraEmails" color="primary" />}
                                        label="Remember Me"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} container justify="flex-end" alignItems='center' className={classes.forgot}>
                                    <Link to="/Forgot">Forgot Password?</Link>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={!isEnabled}
                            >
                                Login
                    </Button>
                        </form>
                    </div>
                </Container>
                <Hero />
            </LoginCard>
            <Copyright />
        </LoginContainer >
    )
}
LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}
const mapStateToProps = state => ({
    alerts: state.alerts,
    isAuthenticated: state.auth.isAuthenticated
})
export default connect(mapStateToProps, { login })(LoginForm)
